import $ from '../vendor/jquery';

$(function () {
  $('[data-widget="quantity-selector"]').each(function () {
    const $element = $(this);
    const input = $element.children('input').get(0);

    $element.on('click', 'button', function (event) {
      const action = $(event.currentTarget).data('action');

      if ('increase' === action) {
        input.stepUp();
      } else if ('decrease' === action) {
        input.stepDown();
      }

      event.preventDefault();
    });
  });
});
