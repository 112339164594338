import $ from '../vendor/jquery';

class FloatingActionButton {
  constructor(element) {
    this._$element = $(element);
    this._$toggle = this._$element.find('[data-target="floating-action-button.toggle"]');
    this._$content = this._$element.find('[data-target="floating-action-button.content"]');
    this._isOpen = false;

    this._bindEventHandlers();
  }

  toggle() {
    this._isOpen ? this.close() : this.open();
  }

  open() {
    this._$element.addClass('ce-floating-action-button--open');
    this._$content.slideDown();

    this._isOpen = true;
  }

  close() {
    this._$element.removeClass('ce-floating-action-button--open');
    this._$content.slideUp();

    this._isOpen = false;
  }

  _bindEventHandlers() {
    this._$toggle.on('click', (event) => {
      event.preventDefault();
      event.currentTarget.blur();

      this.toggle();
    });
  }
}

$(function () {
  $('[data-widget="floating-action-button"]').each(function () {
    const floatingActionButton = new FloatingActionButton(this);
    $(this).data('floatingActionButton', floatingActionButton);
  });
});
