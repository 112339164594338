import $ from '../vendor/jquery';

$(function () {
  $('body').on('click', '[data-action="back-to-top"]', function (event) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    event.currentTarget.blur();

    event.preventDefault();
  });
});

let scrollClassAdded = false;
$(window).on('scroll', function () {
  const scrolled = window.scrollY > 0;

  if (scrolled !== scrollClassAdded) {
    $('body').toggleClass('scrolled', scrolled);
    scrollClassAdded = scrolled;
  }
});
