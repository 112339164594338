import $ from "../vendor/jquery";

const GRID_BREAKPOINTS = {
  xl: 1680,
  lg: 1240,
  md: 769,
  sm: 480
};

let currentCssBreakpoint = getCurrentBreakpoint(),
  previousCssBreakpoint = null;

function getViewportWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
}

function getCurrentBreakpoint() {
  const viewportWidth = getViewportWidth();

  for (let breakpoint in GRID_BREAKPOINTS) {
    if (!GRID_BREAKPOINTS.hasOwnProperty(breakpoint)) {
      continue;
    }

    if (GRID_BREAKPOINTS[breakpoint] <= viewportWidth) {
      return breakpoint;
    }
  }

  return 'xs';
}

function isBreakpoint(breakpoint) {
  const currentBreakpoint = getCurrentBreakpoint();

  if ($.isArray(breakpoint)) {
    return -1 !== $.inArray(currentBreakpoint, breakpoint);
  } else {
    return currentBreakpoint === breakpoint;
  }
}

function registerCssBreakpointEvents() {
  $(window).on('resize', function () {
    const newCssBreakpoint = getCurrentBreakpoint();

    if (newCssBreakpoint !== currentCssBreakpoint) {
      previousCssBreakpoint = currentCssBreakpoint;
      currentCssBreakpoint = newCssBreakpoint;

      $(window).triggerHandler('breakpointChange.mankiewicz', [
        currentCssBreakpoint,
        previousCssBreakpoint
      ])
    }
  });
}

export {getViewportWidth, getCurrentBreakpoint, isBreakpoint, registerCssBreakpointEvents};
