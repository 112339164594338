import $ from '../vendor/jquery';
import * as Parsley from 'parsleyjs';
import 'parsleyjs/dist/i18n/cs';
import 'parsleyjs/dist/i18n/de';
import 'parsleyjs/dist/i18n/es';
import 'parsleyjs/dist/i18n/fr';
import 'parsleyjs/dist/i18n/ja';
import 'parsleyjs/dist/i18n/pl';
import 'parsleyjs/dist/i18n/ru';
import 'parsleyjs/dist/i18n/tr';
import 'parsleyjs/dist/i18n/zh_cn';
import Pikaday from 'pikaday';

try {
  const currentLanguage = document.documentElement.getAttribute('lang').toLowerCase();
  if ('zh-cn' === currentLanguage) {
    Parsley.setLocale('zh-cn');
  } else {
    Parsley.setLocale(currentLanguage.substring(0, 2));
  }
} catch {
}

const CLASS_NAME_FOCUSED = 'form__label--focused';

$('.form').on('submit', function (event) {
  let form = event.currentTarget,
    parsley = $(form).data('Parsley');

  if ((parsley && parsley.isValid()) || form.novalidate || form.checkValidity()) {
    $(event.currentTarget).find('.form__submit-notice').attr('hidden', false);
  }
});

$('.form__label[for]').each((index, element) => {
  const $label = $(element);
  const $input = $('#' + $label.attr('for')).not('[type="date"]');

  function getValue() {
    return $input.is('select') ? $input.children('option:selected').text().trim() : $input.val();
  }

  if (0 < $input.length) {
    if ('' !== getValue()) {
      $label.addClass(CLASS_NAME_FOCUSED);
    }

    $input
      .on('change', () => {
        $label.toggleClass(CLASS_NAME_FOCUSED, ('' !== getValue()));
        $input.blur();
      })
      .on('focus', () => {
        if ('' !== getValue() || !$input.is('select')) {
          $label.addClass(CLASS_NAME_FOCUSED);
        }
      })
      .on('focusout', () => {
        if ('' === getValue()) {
          $label.removeClass(CLASS_NAME_FOCUSED);
        }
      });
  }
});

$('.form__field--datepicker').each((index, element) => {
  function parseDate(dateString) {
    if (!dateString.match(/\d{1,2}\.\d{1,2}\.\d{4}/)) {
      return false;
    }

    const parts = dateString.split('.');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    return new Date(year, month, day);
  }

  function serializeDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return (10 > day ? '0' : '') + day + '.' + (10 > month ? '0' : '') + month + '.' + year;
  }

  new Pikaday({
    field: element,
    firstDay: 1,
    yearRange: [1950, (new Date()).getFullYear()],
    i18n: {
      previousMonth: 'Vorheriger Monat',
      nextMonth: 'Nächster Monat',
      months: ['Januar', 'Feburar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    },
    toString: serializeDate,
    parse: parseDate,
    onSelect: function () {
      let parsley = $(element).data('Parsley');
      if (parsley) {
        parsley.validate();
      }
    }
  });

  element.autocomplete = 'off';
});
