import 'lazysizes';

document.addEventListener('lazybeforeunveil', function (event) {
  const backgroundImage = event.target.getAttribute('data-bg');

  if (backgroundImage) {
    event.target.style.backgroundImage = 'url(' + backgroundImage + ')';
  }

  if ('none' === event.target.preload) {
    event.target.preload = event.target.getAttribute('data-preload') || 'auto';
  }

  if (event.target.getAttribute('data-autoplay') != null) {
    if (event.target.getAttribute('data-expand') && !event.target.autoplay) {
      try {
        event.target.play();
      } catch (er) {
      }
    } else {
      requestAnimationFrame(function () {
        event.target.setAttribute('data-expand', '-10');
        lazySizes.aC(event.target, lazySizes.cfg.lazyClass);
      });
    }
  }
});
