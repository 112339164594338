import $ from '../vendor/jquery';

class ShareMenu {
  constructor(element) {
    this._$element = $(element);
    this._$toggle = this._$element.find('[data-target="toggle"]');
    this._$links = this._$element.find('[data-target="links"]');
    this._isOpen = false;

    this._bindEventHandlers();
  }

  toggle() {
    this._isOpen ? this.close() : this.open();
  }

  open() {
    this._$element.addClass('share--open');

    this._isOpen = true;
  }

  close() {
    this._$element.removeClass('share--open');

    this._isOpen = false;
  }

  _bindEventHandlers() {
    this._$toggle.on('click', (event) => {
      event.preventDefault();
      event.currentTarget.blur();

      this.toggle();
    });

    this._$links.on('click', 'a', (event) => {
      const url = event.currentTarget.href;

      if (!/^mailto:/.test(url)) {
        window.open(url, 'social', 'width=1024,height=768');
        event.preventDefault();
      }
    });
  }
}

$(function () {
  $('[data-widget="share-menu"]').each(function () {
    const shareMenu = new ShareMenu(this);
    $(this).data('shareMenu', shareMenu);
  });
});
