import $ from '../vendor/jquery';
import {getCurrentBreakpoint} from '../includes/utils';
import mapStyles from '../includes/map-styles';

const API_KEY = 'AIzaSyC2qE0e44UBqVlZBo7nSzKF000Md1oX_zQ';

class MapController {
  constructor($elements) {
    this._$elements = $elements;
    this._sdkLoaded = false;
  }

  initialize() {
    this._initializeMaps();
  }

  _initializeMaps() {
    this._loadSdk().then(() => {
      this._$elements.each((index, element) => {
        const $element = $(element);
        const pois = $element.data('pois');
        const center = $element.data('center');
        const zoom = $element.data('zoom');

        const infoWindowTemplate = $element.find('[data-template="info-window"]').html();

        const map = new google.maps.Map($element.find('[data-target="map"]').get(0), {
          center: this._getCenter(center),
          zoom: this._getZoomLevel(zoom),
          styles: mapStyles,
          disableDefaultUI: true,
          zoomControl: true,
        });

        $(window).on('breakpointChange.mankiewicz', () => {
          map.setZoom(this._getZoomLevel());
        });

        if (Array.isArray(pois)) {
          const icon = this._getMarkerIcon();

          pois.forEach((poi) => {
            const marker = new google.maps.Marker({
              position: poi.position,
              title: poi.title,
              icon: icon,
              map: map,
              clickable: !!infoWindowTemplate
            });

            if (infoWindowTemplate) {
              marker.addListener('click', () => {
                const $infoWindow = this._renderInfoWindow(infoWindowTemplate, poi);

                $infoWindow
                  .hide()
                  .appendTo($element)
                  .fadeIn(150, () => {
                    $infoWindow.on('click', '[data-action="close"]', (event) => {
                      event.preventDefault();

                      $infoWindow.fadeOut(150, () => {
                        $infoWindow.remove();
                      });
                    });
                  });
              });
            }
          });
        }
      });
    });
  }

  _getCenter(center) {
    if ('string' === typeof center && center.match(/^\s*\d+(?:\.\d+)?\s*,\s*\d+(?:\.\d+)?\s*$/)) {
      const parts = center.trim().split(/\s*,\s*/);

      return {
        lat: parseFloat(parts[0]),
        lng: parseFloat(parts[1]),
      };
    }

    return {
      lat: 22,
      lng: 16,
    };
  }

  _getZoomLevel(zoom) {
    if ('number' === typeof zoom) {
      return zoom;
    }

    switch (getCurrentBreakpoint()) {
      case 'xs':
        return 0;
      case 'sm':
        return 1;
      default:
        return 2;
    }
  }

  _getMarkerIcon() {
    return {
      url: $('.map__map').data('pin-url'),
      size: new google.maps.Size(20, 24),
      scaledSize: new google.maps.Size(20, 24),
      optimized: false,
    };
  }

  _renderInfoWindow(template, poi) {
    const $infoWindow = $(template);

    $infoWindow.find('[data-target="title"]').text(poi.title);
    $infoWindow.find('[data-target="address"]').html(poi.address.replace(/\r?\n/, '<br>'));

    if (poi.phone) {
      $infoWindow.find('[data-target="phone"]').text(poi.phone).attr('href', 'tel:' + poi.phone);
    } else {
      $infoWindow.find('[data-target="phone"]').next('br').remove();
      $infoWindow.find('[data-target="phone"]').remove();
    }

    if (poi.fax) {
      $infoWindow.find('[data-target="fax"]').text(poi.fax);
    } else {
      $infoWindow.find('[data-target="fax"]').next('br').remove();
      $infoWindow.find('[data-target="fax"]').remove();
    }

    if (poi.email) {
      $infoWindow.find('[data-target="email"]').text(poi.email).attr('href', 'mailto:' + poi.email);
    } else {
      $infoWindow.find('[data-target="email"]').next('br').remove();
      $infoWindow.find('[data-target="email"]').remove();
    }

    if (poi.website) {
      $infoWindow.find('[data-target="website"]').attr('href', poi.website);
    } else {
      $infoWindow.find('[data-target="website"]').next('br').remove();
      $infoWindow.find('[data-target="website"]').remove();
    }

    if (poi.infoUrl) {
      $infoWindow.find('[data-target="info-url"]').attr('href', poi.infoUrl);
    } else {
      $infoWindow.find('[data-target="info-url"]').next('br').remove();
      $infoWindow.find('[data-target="info-url"]').remove();
    }

    return $infoWindow;
  }

  _loadSdk() {
    const dfd = $.Deferred();

    if (this._sdkLoaded) {
      dfd.resolve();
    } else {
      $.getScript(this._getSdkUrl()).then(() => {
        this._sdkLoaded = true;

        dfd.resolve();
      });
    }

    return dfd.promise();
  }

  _getSdkUrl() {
    const siteLanguage = $('html').attr('lang');

    let url = 'https://maps.googleapis.com/maps/api/js?key=' + encodeURIComponent(API_KEY);
    if (siteLanguage) {
      url += '&language=' + encodeURIComponent(siteLanguage);
    }

    return url;
  }
}

$(function () {
  const $elements = $('[data-widget="map"]');

  if (0 < $elements.length) {
    const controller = new MapController($elements);
    controller.initialize();
  }
});
