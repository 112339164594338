import $ from '../vendor/jquery';

$(function () {
  $('[data-widget="accordion"]').each(function () {
    const $element = $(this);

    $element.on('click', '[data-action="toggle"]', (event) => {
      const $button = $(event.currentTarget);
      const $pane = $button.closest('.accordion__pane');

      $button.attr('aria-expanded', 'true' === $button.attr('aria-expanded') ? 'false' : 'true');
      $pane.find('.accordion__pane-title').toggleClass('accordion__pane-title--open');
      $pane.find('.accordion__pane-body').toggleClass('accordion__pane-body--open').slideToggle(300);

      event.preventDefault();
      event.currentTarget.blur();
    });
  });
});
