import $ from '../vendor/jquery';

$(function () {
  $('[data-widget="variant-selector"]').each(function () {
    const $element = $(this);
    const $select = $element.find('select');
    const $priceTargets = $($element.data('priceTarget'));
    const $skuTargets = $($element.data('skuTarget'));
    const $titleTargets = $($element.data('titleTarget'));

    $select.on('change', function (event) {
      const $option = $select.children('option:selected');

      const price = $option.data('regularPrice');

      $priceTargets.each(function () {
        const $target = $(this);

        if (price) {
          if (!$target.data('initialPrice')) {
            $target.data('initialPrice', $target.text().trim());
          }

          $target.text(price);
        } else {
          if ($target.data('initialPrice')) {
            $target.text($target.data('initialPrice'));
          }
        }
      });

      const sku = $option.data('beVariantCustomSku') ?? '';

      $skuTargets.text(sku).parent().toggle('' !== sku);

      const title = $option.data('beVariantCustomTitle') ?? '';

      $titleTargets.text(title);
    });
  });
});
